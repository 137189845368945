<template>
  <Chip class="chip" icon :selected="!filter.isClear.value" @click="popupShown = true">
    {{ filter.label?.value ?? filter.name }}
    <IconChevronDown class="icon" />
  </Chip>
  <Teleport to="body">
    <MobilePopup :shown="popupShown">
      <MobilePopupHeader :title="filter.name" @close="popupShown = false" />
      <div class="horiz-container"><slot /></div>
      <Navbar>
        <BaseButton v-if="!filter.isClear.value" transparent small @click="filter.clear">Сбросить</BaseButton>
        <BaseButton class="save-btn" color-primary small @click="popupShown = false">Сохранить{{ total !== undefined ? ` (${total})` : '' }}</BaseButton>
      </Navbar>
    </MobilePopup>
  </Teleport>
</template>

<script setup>
import { IconChevronDown } from '@tabler/icons-vue'

const props = defineProps({
  filter: Object,
  total: Number
})

const popupShown = ref(false)
</script>

<style scoped lang="scss">
.chip {
  flex-shrink: 0;
}

.save-btn {
  flex: 1;
}
</style>
