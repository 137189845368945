<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6919 10.9375H5C4.48223 10.9375 4.0625 10.5178 4.0625 10C4.0625 9.48223 4.48223 9.0625 5 9.0625H16.6919C17.1 7.61968 18.4265 6.5625 20 6.5625C21.5735 6.5625 22.9 7.61968 23.3081 9.0625H25C25.5178 9.0625 25.9375 9.48223 25.9375 10C25.9375 10.5178 25.5178 10.9375 25 10.9375H23.3081C22.9 12.3803 21.5735 13.4375 20 13.4375C18.4265 13.4375 17.1 12.3803 16.6919 10.9375ZM18.4375 10C18.4375 9.13706 19.1371 8.4375 20 8.4375C20.8629 8.4375 21.5625 9.13706 21.5625 10C21.5625 10.8629 20.8629 11.5625 20 11.5625C19.1371 11.5625 18.4375 10.8629 18.4375 10Z" fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3081 20.9375H25C25.5178 20.9375 25.9375 20.5178 25.9375 20C25.9375 19.4822 25.5178 19.0625 25 19.0625H13.3081C12.9 17.6197 11.5735 16.5625 10 16.5625C8.42653 16.5625 7.09998 17.6197 6.6919 19.0625H5C4.48223 19.0625 4.0625 19.4822 4.0625 20C4.0625 20.5178 4.48223 20.9375 5 20.9375H6.6919C7.09998 22.3803 8.42653 23.4375 10 23.4375C11.5735 23.4375 12.9 22.3803 13.3081 20.9375ZM8.4375 20C8.4375 19.1371 9.13706 18.4375 10 18.4375C10.8629 18.4375 11.5625 19.1371 11.5625 20C11.5625 20.8629 10.8629 21.5625 10 21.5625C9.13706 21.5625 8.4375 20.8629 8.4375 20Z" fill="currentColor" />
  </svg>
</template>

<script setup>

</script>

<style scoped lang="scss">

</style>
